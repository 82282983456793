<template>
  <div>
    <el-dialog top="5vh"
               title="待确认课时"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               @open="open"
               :close-on-click-modal="false"
               width="950px">
      <div>
        <el-table class="result"
                  :data="confrimList"
                  :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                  :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                  border
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection"
                           width="50"
                           fixed="left">
          </el-table-column>
          <el-table-column prop="id"
                           label="ID"
                           align="center"
                           width="80">
          </el-table-column>

          <el-table-column label="课时主题"
                           align="center"
                           width="150">
            <template slot-scope="scope">
              {{scope.row.course_theme}}
            </template>
          </el-table-column>
          <el-table-column label="课程时长"
                           align="center"
                           width="150">
            <template slot-scope="scope">
              {{scope.row.final_real_lesson_hour}}小时
            </template>
          </el-table-column>

          <el-table-column label="加入班级时间"
                           align="center"
                           width="150">
            <template slot-scope="scope">
              {{scope.row.student_join_grade_time?$moment(scope.row.student_join_grade_time).format("YYYY-MM-DD HH:mm"):""}}
            </template>
          </el-table-column>
          <el-table-column label="课时状态"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.lesson_status==0"
                      size="mini"
                      type="warning">待确认</el-tag>
              <el-tag v-if="scope.row.lesson_status==1"
                      size="mini">待回访</el-tag>
              <el-tag v-if="scope.row.lesson_status==2"
                      size="mini"
                      type="danger">已拒绝</el-tag>
              <el-tag v-if="scope.row.lesson_status==3"
                      size="mini"
                      type="success">已完成</el-tag>
              <!--     <el-tag
                v-if="scope.row.lesson_status==4"
                size="mini"
                type="info"
              >已取消</el-tag> -->
              <el-tag v-if="scope.row.lesson_status==5"
                      size="mini"
                      type="info">已完结</el-tag>
              <el-tag v-if="scope.row.lesson_status==6"
                      size="mini"
                      type="info">已暂停</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="创建时间"
                           align="center"
                           width="150">
            <template slot-scope="scope">
              {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
            </template>
          </el-table-column>
          <el-table-column prop="name"
                           label="操作"
                           width="100"
                           fixed="right">
            <template slot-scope="scope">
              <div class="templateLink">
                <el-link type="primary"
                         :underline="false"
                         @click="handleToChoose(scope.row)">待确认</el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="display:flex;marginTop:10px"
             @click="handleConfirm">
          <el-button>确认课时</el-button>
        </div>
      </div>
    </el-dialog>
    <Display :isVisibles="displayVisible"
             :chooseList="chooseList"
             @palyClose="palyClose"
             @submit="submit"></Display>
  </div>
</template>
<script>
import Display from '@/components/DialogComponents/ClassService/confirmDisplay.vue'
export default {
  components: {
    Display,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    confrimList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
    },
    confrimList: {
      handler(newVal, oldVal) {
        console.log('this.dataList', newVal)
      },
      deep: true, // 启用深度监听
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      displayVisible: false,
      dataList: [],
      chooseList: [],
    }
  },
  methods: {
    handleToClose() {
      this.$emit('close')
    },
    palyClose() {
      this.displayVisible = false
      this.chooseList = []
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    submit() {
      this.$emit('submit')
    },
    open() {},
    handleToChoose(row) {
      this.chooseList = []
      this.chooseList.push(row)
      this.dialogFormVisible = false
      this.displayVisible = true
    },
    handleConfirm() {
      if (this.chooseList.length == 0) {
        this.$message.error('至少选择一条数据')
        return
      }
      this.dialogFormVisible = false
      this.displayVisible = true
    },
  },
}
</script>
<style scoped lang="less">
</style>


