<template>
  <div>
    <el-dialog top="5vh"
               title="待确认课时"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               @open="open"
               :close-on-click-modal="false"
               width="900px">
      <el-table class="result"
                :data="chooseList"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border>

        <el-table-column prop="id"
                         label="ID"
                         align="center"
                         width="80">
        </el-table-column>

        <el-table-column label="课时主题"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.course_theme}}
          </template>
        </el-table-column>
        <el-table-column label="课程时长"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.final_real_lesson_hour}}小时
          </template>
        </el-table-column>

        <el-table-column label="加入班级时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.student_join_grade_time?$moment(scope.row.student_join_grade_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column label="课时状态"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lesson_status==0"
                    size="mini"
                    type="warning">待确认</el-tag>
            <el-tag v-if="scope.row.lesson_status==1"
                    size="mini">待回访</el-tag>
            <el-tag v-if="scope.row.lesson_status==2"
                    size="mini"
                    type="danger">已拒绝</el-tag>
            <el-tag v-if="scope.row.lesson_status==3"
                    size="mini"
                    type="success">已完成</el-tag>
            <!--     <el-tag
                v-if="scope.row.lesson_status==4"
                size="mini"
                type="info"
              >已取消</el-tag> -->
            <el-tag v-if="scope.row.lesson_status==5"
                    size="mini"
                    type="info">已完结</el-tag>
            <el-tag v-if="scope.row.lesson_status==6"
                    size="mini"
                    type="info">已暂停</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>

      </el-table>

      <div class="form-modular">
        <el-form :model="dataForm"
                 label-position="left"
                 ref="formRules"
                 label-width="100px"
                 size="small">
          <el-form-item label="回访记录：">
            <div class="text-pic">
              <el-form-item prop="handle_content">
                <el-input type="textarea"
                          v-model="dataForm.handle_content"
                          placeholder="请输入回访记录"></el-input>
              </el-form-item>
              <div class="upload">
                <el-upload :action="imgUploadUrl"
                           list-type="picture-card"
                           :on-success="
                      (...event) => {
                        handleFileSuccess(event, index);
                      }
                    "
                           :on-remove="
                      (...event) => {
                        handleRemove(event, index);
                      }
                    "
                           :limit="9"
                           :file-list="dataForm.handle_picture_urls">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
            </div>
          </el-form-item>

        </el-form>
      </div>
      <div class="footer"
           @click="handleConfirm()">
        <el-button type="primary">确认</el-button>

      </div>
    </el-dialog>
  </div>
</template>
<script>
import { imgUploadUrl, fileUploadUrl } from '@/request/http'
import { addBulk } from '@/request/api'
import { getStorage } from '@/utils/helper'
export default {
  props: {
    isVisibles: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    chooseList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  watch: {
    isVisibles(val) {
      this.dialogFormVisible = val
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      imgUploadUrl: imgUploadUrl(),
      dataForm: { handle_picture_urls: [] },
    }
  },
  methods: {
    handleToClose() {
      this.$emit('palyClose')
    },
    handleFileSuccess(param) {
      this.dataForm.handle_picture_urls.push({
        name: param[1].name,
        url: param[0].data.url,
      })
      console.log('param1', param, this.dataForm.handle_picture_urls)
    },
    handleRemove(param) {
      console.log('param2', param)
    },
    open() {},
    handleConfirm() {
      let arr_lesson_log_id = []
      this.chooseList.map((item) => {
        arr_lesson_log_id.push(item.id)
      })

      let data = {
        arr_lesson_log_id: arr_lesson_log_id,
        is_by_handle: 1,
        user_type: 'student',
        student_id: this.chooseList[0].student_id,
        handle_content: this.dataForm.handle_content,
        handle_picture_urls:
          this.dataForm.handle_picture_urls &&
          this.dataForm.handle_picture_urls.length
            ? JSON.stringify(this.dataForm.handle_picture_urls)
            : '',
        handle_area: 5,
        student_confirm_status: 1,
        handle_type: 0,
        handle_learn_manager_id: getStorage('userInfo').id,
      }
      addBulk(data).then((res) => {
        this.$message.success('操作成功')
        this.$emit('submit')
        this.handleToClose()
      })
    },
  },
}
</script>
<style scoped lang="less">
.footer {
  display: flex;
  justify-content: flex-end;
}
.form-modular {
  padding: 16px 0px;
  .text-pic {
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #dcdfe6;
    padding: 16px;

    /deep/.el-textarea__inner {
      border: 0;
      resize: none;
      padding: 0px;
    }
    .upload {
      /deep/.el-upload--picture-card {
        width: 56px;
        height: 56px;
        line-height: 65px;
      }
      /deep/.el-upload-list__item {
        width: 56px;
        height: 56px;
        line-height: 65px;
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      margin-bottom: 16px;
      .name {
        font-weight: 800;
      }
    }
  }
}
</style>