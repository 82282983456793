<template>
  <el-container>
    <vHead @collapse="collapse"></vHead>
    <el-container>
      <el-aside style="width: auto">
        <el-menu class="el-menu-vertical-demo"
                 background-color="#394167"
                 text-color="#fff"
                 active-text-color="#409EFF"
                 unique-opened
                 router
                 :default-active="onRoutes"
                 :collapse="iscollapse">
          <template v-for="item in menuList">
            <template v-if="item.subs">
              <el-submenu :index="item.index"
                          :key="item.index">
                <template slot="title">
                  <!--                 <i :class="item.icon"></i>
                  <span slot="title">{{ item.title }}</span> -->
                  <img :src="item.icon"
                       alt=""
                       class="menu_img" />
                  <span slot="title">{{ item.title }} </span>
                </template>
                <template v-for="(subItem, subIndex) in item.subs">
                  <el-submenu v-if="subItem.subs"
                              :index="subItem.index"
                              :key="subItem.index">
                    <template slot="title">{{ subItem.title }} </template>
                    <el-menu-item v-for="(threeItem, i) in subItem.subs"
                                  :key="i"
                                  :index="threeItem.index">{{ threeItem.title }}</el-menu-item>
                  </el-submenu>

                  <el-menu-item v-else
                                :index="subItem.index"
                                :key="subIndex">
                    <div class="subItem-title">
                      <span> {{ subItem.title }}</span>
                      <span class="subItem-tag"
                            v-if="
                          subItem.title == '师资推荐' &&
                          (uncheckCount || uncheck)
                        ">
                        {{ uncheckCount ? uncheckCount : uncheck }}
                      </span>
                    </div>
                  </el-menu-item>
                </template>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item :index="item.index"
                            :key="item.index">
                <img :src="item.icon"
                     alt=""
                     class="menu_img" />
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import vHead from '../components/Eheader/Eheader.vue'
import { computePermission } from '../utils/helper'
import { crmUrl, getStorage } from '@/utils/helper.js'
const menus = [
  {
    icon: require('../assets/images/menu0.png'),
    index: '/overview',
    title: '总 览',
  },
  {
    icon: require('../assets/images/menu0.png'),
    index: '/index',
    title: '总 览',
  },
  {
    icon: require('../assets/images/menu1.png'),
    index: '/service',
    title: '服 务',
    subs: [
      {
        index: '/service/serviceorder',
        title: '师资服务',
      },

      {
        index: '/service/dispatchorder',
        title: '师资派遣',
      },
      {
        index: '/service/ordermatching',
        title: '师资推荐',
      },
    ],
  },
  {
    icon: require('../assets/images/menu_classservice.png'),
    index: '/classservice',
    title: '课 时',
    subs: [
      {
        index: '/classservice/student',
        title: '学员管理',
      },
      {
        index: '/classservice/class',
        title: '班级管理',
      },
      {
        index: '/classservice/plan/createplan',
        title: '班级计划',
      },
      {
        index: '/classservice/plan',
        title: '计划管理',
      },
      {
        index: '/classservice/record',
        title: '课时管理',
      },
      {
        index: '/classservice/teacher',
        title: '老师管理',
      },
      {
        index: '/classservice/evaluate',
        title: '评价管理',
      },

      {
        index: '/classservice/servicerecord',
        title: '服务记录',
      },
      {
        index: '/shareorder/datamanagement',
        title: '资料管理',
      },
    ],
  },
  {
    icon: require('../assets/images/menu_tr.png'),
    index: '/tr',
    title: '师 资',
    subs: [
      {
        index: '/tr/share',
        title: '师资推荐',
      },
      {
        index: '/tr/order',
        title: '订单管理',
      },
      {
        index: '/tr/classregistration',
        title: '课时登记',
      },
      {
        index: '/tr/classhourrecord',
        title: '课时记录',
      },
      {
        index: '/tr/admixture',
        title: '报录查询',
      },
      {
        index: '/tr/teachers',
        title: '老师管理',
      },
      {
        index: '/tr/database',
        title: '资料查询',
      },
      {
        index: '/tr/record',
        title: '跟进记录',
      },
      {
        index: '/tr/signorder',
        title: '我的签单',
      },
    ],
  },
  {
    icon: require('../assets/images/menu_share.png'),
    index: '/shareorder',
    title: '共 享',
    subs: [
      {
        index: '/shareorder/order',
        title: '订单管理',
      },
      {
        index: '/shareorder/reserveteachers',
        title: '预留师资',
      },
      {
        index: '/shareorder/record',
        title: '操作记录',
      },
      {
        index: '/shareorder/topupgift',
        title: '充值赠送',
      },
      {
        index: '/shareorder/discountroll',
        title: '优惠券包',
      },
      {
        index: '/shareorder/advertising',
        title: '广告中心',
      },
      {
        index: '/shareorder/helpCenter',
        title: '帮助中心',
      },
      {
        index: '/shareorder/leaveWord',
        title: '留言管理',
      },
      {
        index: '/shareorder/eventMangement',
        title: '活动管理',
      },
    ],
  },
  {
    icon: require('../assets/images/menu2.png'),
    index: '/finance',
    title: '财 务',
    subs: [
      {
        index: '/finance/billmanagement',
        title: '机构账单',
      },
      {
        index: '/finance/balancemanagement',
        title: '机构余额',
      },
      {
        index: '/finance/transferapproval',
        title: '转账审批',
      },
      {
        index: '/finance/invoicingrequisition',
        title: '开票审批',
      },
      {
        index: '/finance/seniorbill',
        title: '学长课酬',
      },
      {
        index: '/finance/withdraw',
        title: '提现审核',
      },
      {
        index: '/classservice/task',
        title: '任务管理',
      },
      {
        index: '/finance/transactionrecord',
        title: '交易记录',
      },
    ],
  },
  {
    icon: require('../assets/images/menu3.png'),
    index: '/mechanism',
    title: '机 构',
    subs: [
      {
        index: '/mechanism/accountmanagement',
        title: '账号管理',
      },
      {
        index: '/mechanism/mechanismmanagement',
        title: '机构管理',
      },
      {
        index: '/mechanism/institutionalrole',
        title: '机构角色',
      },
      {
        index: '/mechanism/institutionalauthority',
        title: '机构权限',
      },
      {
        index: '/mechanism/institutionaldictionary',
        title: '机构字典',
      },
      {
        index: '/mechanism/apply',
        title: '机构申请',
      },
      {
        index: '/mechanism/orderprice',
        title: '订单价格',
      },
      /*       {
             index: "/mechanism/group",
             title: "机构组",
           }, */
    ],
  },
  {
    icon: require('../assets/images/menu4.png'),
    index: '/staff',
    title: '员 工',
    subs: [
      {
        index: '/staff/employeemanagement',
        title: '员工管理',
      },
      {
        index: '/staff/departmentmanagement',
        title: '部门管理',
      },
      {
        index: '/staff/rolemanagement',
        title: '角色管理',
      },
      {
        index: '/staff/userrights',
        title: '用户权限',
      },
    ],
  },

  {
    icon: require('../assets/images/menu6.png'),
    index: '/settings',
    title: '设 置',
    subs: [
      {
        index: '/settings/basicsettings',
        title: '基本设置',
      },
      {
        index: '/settings/rootdictionary',
        title: '字典设置',
      },
    ],
  },
]
export default {
  components: {
    vHead,
  },
  data() {
    return {
      iscollapse: false,
      menuList: [],
      uncheck: 0,
    }
  },

  mounted() {},
  created() {
    /*   this.menuList = menus */
    this.menuList = JSON.parse(JSON.stringify(menus)).filter((item) => {
      return this.computeRoute(item)
    })
    this.receiptFetchList()
  },
  computed: {
    ...mapState(['uncheckCount']),
    onRoutes() {
      const route = this.$route
      const { meta, path } = route
      // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
      // meta中 有activeMenu 字段的子页面，都会显示高亮
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  methods: {
    receiptFetchList() {
      const params = {
        isPaging: 0,
        statues: '4,3',
      }
      params.ref_sender_manage_user_ids = getStorage('userInfo').id
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder/list`,
        method: 'get', //可以省略 不写默认是get
        params: params,
      }).then((response) => {
        if (response.data.code == 0) {
          this.uncheck = response.data.data.count
        }
      })
    },
    collapse(collapse) {
      this.iscollapse = collapse
    },
    computeRoute(item) {
      if (item.subs) {
        item.subs = item.subs.filter((sub) => {
          return this.computeRoute(sub)
        })
        if (item.subs.length) {
          return true
        }
      }
      return computePermission(item.index)
    },
  },
}
</script>

<style lang="less" scoped>
.subItem-title {
  position: relative;
  .subItem-tag {
    position: absolute;
    line-height: 1.2;
    top: 6px;
    right: 35px;
    padding: 0 5px !important;
    background-color: #f56c6c;
    border-radius: 10px;
    color: #fff;
  }
}

.el-container {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0;
  bottom: 0;
}

.el-header {
  padding: 0;
  z-index: 1000;
}

.el-header .fr {
  float: right;
}

.el-header .el-menu {
  border-bottom: none;
}

.el-main {
  padding-top: 59px;
}

.el-main {
  background: #f5f5f5;
}

.el-aside .el-menu {
  border-right: none;
}

.menu_img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

// 菜单收缩栏样式(关闭时)
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

.el-menu {
  height: 100%;
}

.el-aside {
  padding-top: 40px;
}
</style>