<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item>评价管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button icon="el-icon-download"
                   @click="handleExport"
                   size="small">导出</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">

        <el-form-item label="学员">
          <el-input v-model.trim="query.student_keyword "
                    placeholder="请输入学员姓名或手机号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="学管师">
          <el-input v-model.trim="query.learn_manage_keyword "
                    placeholder="请输入学管师名称"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="机构简称">
          <el-input v-model.trim="query.student_company_keyword"
                    placeholder="请输入机构简称"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="对于学管评低于"
                      prop="type">
          <el-select v-model="query.low_of_learn_manage_score"
                     placeholder="请选择分数"
                     style="width: 100%;"
                     @change="fetchList(true)">
            <el-option v-for="(item, index) in learnList"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对于机构评低于"
                      prop="type">
          <el-select v-model="query.low_of_total_service_score"
                     placeholder="请选择分数"
                     style="width: 100%;"
                     @change="fetchList(true)">
            <el-option v-for="(item, index) in learnList"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="创建日期"
                      prop="last_advance_time">
          <el-date-picker v-model="create_time"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="timestamp"
                          @change="fetchList(true)">
          </el-date-picker>
        </el-form-item>

      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column type="selection"
                         width="50"
                         align="center">
        </el-table-column>
        <el-table-column prop="id"
                         label="ID"
                         width="50"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="statistic_count_plan_access "
                         label="学员信息"
                         width="170"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.student_realname ? scope.row.student_realname :'' 
            }}/{{
              scope.row.student_phone ? scope.row.student_phone :'' 
            }}
          </template>
        </el-table-column>
        <el-table-column prop="learn_manage_nickname"
                         label="学管老师"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="learn_manage_score"
                         label="学管评分"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.learn_manage_score
            }}分
          </template>
        </el-table-column>
        <el-table-column prop="learn_manage_evaluate"
                         label="对学管评价"
                         width="250"
                         align="left"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="evaluate">
              {{
              scope.row.learn_manage_evaluate
            }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="student_company_name"
                         label="机构简称"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.student_company_name
            }}
          </template>
        </el-table-column>

        <el-table-column prop="total_service_score"
                         label="对机构评分"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.total_service_score
            }}分
          </template>
        </el-table-column>

        <el-table-column prop="total_service_evaluate"
                         label="对机构评价"
                         width="250"
                         align="left"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="evaluate"> {{
              scope.row.total_service_evaluate
            }}</span>

          </template>
        </el-table-column>
        <el-table-column prop="total_service_advice"
                         label="整体服务评价与建议"
                         width="250"
                         align="left"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="evaluate"> {{
              scope.row.total_service_advice
            }}</span>

          </template>
        </el-table-column>
        <el-table-column prop="create_time"
                         label="创建时间"
                         width="180"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
             scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time"
                         label="更新时间"
                         width="180"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
             scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="操作"
                         width="100"
                         fixed="right">
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link type="primary"
                       style="marginLeft:20px"
                       :underline="false"
                       @click="handleDetle(scope.row)">删除</el-link>
            </div>
          </template>
        </el-table-column>

      </el-table>

      <div style="margin-top: 16px;">
        <div style="display:flex"
             @click="batchesDetete()"><span>已选{{num}}条</span><span class="total">|</span><span class="detele">批量删除</span></div>
        <div class="block">
          共{{ pageTotal }}条
          <el-pagination background
                         layout="prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <el-card style="marginTop:20px">
      <div class="static"><span class="blue"></span><span class="title">统计</span><span class="des">（以下统计结果根据筛选结果实时变动）</span>

      </div>
      <div class="totalNum">
        <el-row>
          <el-col :span="8">
            <div class="grid-content bg-purple totalEvaliate">
              <p class="totalNums">当前总评价数</p>
              <div style="fontSize:16px;marginTop:20px"> <span class="totalNums">{{statistic_obj.total_evaluate_count}}</span>条</div>

            </div>

          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light  learnScore">
              <p class="totalNums">对学管评分</p>
              <div class="learnBottom">
                <div>
                  <p class="learnItem"> <span class="numm">{{statistic_obj.max_learn_manage_score }}</span>分</p>
                  <p class="learnItem"
                     style="color: #606266;">最高分</p>
                </div>
                <div>
                  <p class="learnItem"
                     style="color: #E34D59;"><span class="numm">{{statistic_obj.min_learn_manage_score}}</span>分</p>
                  <p class="learnItem"
                     style="color: #606266;">最低分</p>
                </div>
                <div>
                  <p class="learnItem"><span class="numm">{{statistic_obj.avg_learn_manage_score }}</span>分</p>
                  <p class="learnItem"
                     style="color: #606266;">平均分</p>
                </div>

              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <div class="grid-content bg-purple-light  learnScore">
                <p class="totalNums">对机构评分</p>
                <div class="learnBottom">
                  <div>
                    <p class="learnItem"> <span class="numm">{{statistic_obj.max_total_service_score  }}</span>分</p>
                    <p class="learnItem"
                       style="color: #606266;">最高分</p>
                  </div>
                  <div>
                    <p class="learnItem"
                       style="color: #E34D59;"><span class="numm">{{statistic_obj.min_total_service_score  }}</span>分</p>
                    <p class="learnItem"
                       style="color: #606266;">最低分</p>
                  </div>
                  <div>
                    <p class="learnItem"><span class="numm">{{statistic_obj.avg_total_service_score  }}</span>分</p>
                    <p class="learnItem"
                       style="color: #606266;">平均分</p>
                  </div>

                </div>
              </div>

            </div>
          </el-col>
        </el-row>

      </div>
    </el-card>

    <el-dialog append-to-body
               top="5vh"
               title="评价详情"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               :close-on-click-modal="false"
               width="620px">
      <div class="diag">{{content}}</div>

    </el-dialog>
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import {
  manageEvaluateList,
  evaluateDelete,
  evaluateExport,
} from '@/request/api'
export default {
  components: {
    TableTittle,
  },
  data() {
    return {
      create_time: null,
      dialogFormVisible: false,
      TableName: '评价列表',
      tableData: [],
      chooseList: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        is_get_statistic_info: 1,
      },
      pageTotal: 0,
      content: null,
      learnList: [
        { label: '1分', value: '1' },
        { label: '2分', value: '2' },
        { label: '3分', value: '3' },
        { label: '4分', value: '4' },
        { label: '5分', value: '5' },
        { label: '6分', value: '6' },
        { label: '7分', value: '7' },
        { label: '8分', value: '8' },
        { label: '9分', value: '9' },
        { label: '10分', value: '10' },
      ],
      num: 0,
      statistic_obj: {},
    }
  },
  created() {
    this.fetchList()
    this.$store.dispatch('getStaffOptions')
  },
  computed: {},
  methods: {
    computeNum() {
      this.num = this.chooseList.length
    },
    detail(row, type) {
      if (type == 1) {
        this.content = row.learn_manage_evaluate
      } else if (type == 2) {
        this.content = row.total_service_evaluate
      } else if (type == 3) {
        this.content = row.total_service_advice
      }
      this.dialogFormVisible = true
    },
    handleSelectionChange(val) {
      this.chooseList = val
      this.computeNum()
    },
    emitClose() {
      this.content = ''
      this.dialogFormVisible = false
    },
    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code)
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (this.create_time) {
        params.start_create_time = this.create_time[0]
        params.end_create_time = this.create_time[1]
      }

      manageEvaluateList(params).then((response) => {
        this.tableData = response.data.data.list || []
        this.statistic_obj = response.data.data.statistic_obj
        this.pageTotal = response.data.data.count
      })
    },
    handleExport() {
      this.$confirm('是否导出数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = Object.assign({}, this.query)
        if (this.create_time) {
          params.start_create_time = this.create_time[0]
          params.end_create_time = this.create_time[1]
        }
        evaluateExport(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success('导出成功')
            //创建a标签用于跳转至下载链接
            // console.log("src",src)
            var str = res.data.data.url.replace(
              /http:/,
              window.location.protocol
            )
            this.$store.commit('downLoad', str)
          }
        })
      })
    },
    batchesDetete() {
      if (this.chooseList.length == 0) {
        this.$message({
          type: 'error',
          message: '至少选择一条数据!',
        })
        return
      }
      let choose = []
      this.chooseList.map((item) => {
        choose.push(item.id)
      })
      this.$confirm('是否删除评价?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          evaluateDelete({
            ids: choose,
          }).then((res) => {
            if (res.data.code == 0) {
              this.fetchList()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.chooseList = []
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleDetle(row) {
      let ids = []
      ids.push(row.id)
      this.$confirm('是否删除此条评价?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          evaluateDelete({
            ids: ids,
          }).then((res) => {
            if (res.data.code == 0) {
              this.fetchList()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>

<style lang="less" scoped>
.evaluate {
  cursor: pointer;
}
.diag {
  min-height: 80px;
}
.content {
}
.detele {
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  line-height: 26px;
  width: 72px;
  height: 26px;
  color: #f56c6c;
  border-radius: 4px;
  border: 1px solid #f56c6c;
}
.total {
  margin-top: 3px;
  margin-left: 10px;
  margin-right: 10px;
}
.static {
  display: flex;
  align-items: center;
  .blue {
    margin-right: 10px;
    display: inline-block;
    width: 4px;
    height: 20px;
    background: #409eff;
    border-radius: 4px 4px 4px 4px;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
  }
  .desc {
    font-size: 14px;
  }
}
.totalNum {
  margin-top: 15px;
  height: 175px;
  background: #ecf2fe;
}
.totalEvaliate {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 175px;
  .totalNums {
    font-weight: 700;
    font-size: 20px;
    color: #303133;
  }
  .xian {
    width: 1px;
    height: 82px;
    background: red;
  }
}

.learnScore {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 175px;
  .totalNums {
    font-weight: 700;
    font-size: 20px;
    color: #303133;
  }
  .xian {
    width: 1px;
    height: 82px;
    background: #dcdfe6;
  }
  .learnBottom {
    margin-top: 20px;
    gap: 40px;
    display: flex;
    justify-content: space-around;
    .learnItem {
      text-align: center;
      font-size: 14px;
      .numm {
        font-weight: 700;
        font-size: 21px;
      }
    }
  }
}
</style>
