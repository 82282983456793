import Class from "../views/ClassService/Class/index.vue";
import ClassRecord from "../views/ClassService/Class/record.vue";
import Record from "../views/ClassService/Record/index.vue";
import Plan from "../views/ClassService/Plan/index.vue";
import PlanCalendar from "../views/ClassService/Plan/calendar.vue";
import Import from "../views/ClassService/Plan/import.vue";
import Student from "../views/ClassService/Student/index.vue";
import Teacher from "../views/ClassService/Teacher/index.vue";
import Evaluate from "../views/ClassService/evaluate/index.vue";
import ClassHour from "../views/ClassService/Teacher/classhour.vue";
import TeacherBill from "../views/ClassService/Teacher/bill.vue";
import RecordConfim from "../views/ClassService/Record/confirm.vue";
import Task from "../views/ClassService/Task/index.vue";
import TaskDetail from "../views/ClassService/Task/detail.vue";
import SRecord from "../views/ClassService/Student/record.vue";
import ServiceRecord from "../views/ClassService/ServiceRecord/index.vue";
import createPlan from "../views/ClassService/Plan/createPlan.vue";



export default [
    /*** 课   时 ***/
    {
        path: '/classservice/class',
        component: Class,
        meta: { title: '班级管理' }
    }, {
        path: '/classservice/class/record/:id',
        component: ClassRecord,
        meta: { title: '班级变更记录' }
    },
    {
        path: '/classservice/record',
        component: Record,
        meta: { title: '课时管理' }
    }, {
        path: '/classservice/record/confirm',
        component: RecordConfim,
        meta: { title: '课时管理确认详情' }
    }, {
        path: '/classservice/student',
        component: Student,
        meta: { title: '学员管理' }
    }, {
        path: '/classservice/teacher',
        component: Teacher,
        meta: { title: '老师管理' }
  },
    {
        path: '/classservice/evaluate',
        component: Evaluate,
        meta: { title: '评价管理' }
    },
    {
        path: '/classservice/teacher/classhour',
        component: ClassHour,
        meta: { title: '老师管理课时记录' }
    },
    {
        path: '/classservice/teacher/bill',
        component: TeacherBill,
        meta: { title: '老师管理课酬' }
    },

    {
        path: '/classservice/task',
        component: Task,
        meta: { title: '任务管理' }
    },
    {
        path: '/classservice/task/:id',
        component: TaskDetail,
        meta: { title: '任务管理详情' }
    },
    {
        path: '/classservice/plan',
        component: Plan,
      meta: { title: '课程计划' }
    },
    {
        path: '/classservice/plan/calendar',
        component: PlanCalendar,
        meta: { title: '课程计划日历表' }
    },
    {
        path: '/classservice/plan/import',
        component: Import,
        meta: { title: '导入' }
    },
    {
        path: '/classservice/student/record',
        component: SRecord,
        meta: { title: '服务记录' }
    },
    {
        path: '/classservice/servicerecord',
        component: ServiceRecord,
        meta: { title: '服务记录' }
    },
    {
        path: '/classservice/plan/createplan',
        component: createPlan,
        meta: { title: '班级计划' }
    },
    

    
]