<template>
  <div class="content">
    <el-dialog top="5vh"
               title="计划预览"
               :visible.sync="dialogFormVisible"
               @close="dialogFormVisible = false"
               :close-on-click-modal="false"
               width="720px"
               :append-to-body="true">
      <TableTittle :title="gradeDetail.name"></TableTittle>
      <!-- <div @click="createPoster()">1111</div> -->
      <!-- ref="postHtml" -->
      <div class="view">
        <div class="line">
          <div class="line-lef">班级类型</div>
          <div class="line-rig">
            {{
              gradeDetail.type == 0
                ? "一对一"
                : gradeDetail.type == 1
                ? "小班课"
                : gradeDetail.type == 2
                ? "大班课"
                : "试听课"
            }}
          </div>
        </div>
        <div class="line">
          <div class="line-lef">辅导院校</div>
          <div class="line-rig">{{ gradeDetail.apply_college_name }}</div>
        </div>
        <div class="line">
          <div class="line-lef">辅导专业</div>
          <div class="line-rig">{{ gradeDetail.apply_major_name }}</div>
        </div>
        <div class="line">
          <div class="line-lef">辅导学院</div>
          <div class="line-rig">{{ gradeDetail.apply_academy_name }}</div>
        </div>
        <div class="line">
          <div class="line-lef">辅导科目</div>
          <div class="line-rig">
            {{ getSubject(gradeDetail.apply_subjects) }}
          </div>
        </div>
        <div class="line">
          <div class="line-lef">辅导课时</div>
          <div class="line-rig">
            {{ (gradeDetail.cocah_lesson_hour / rate).toFixed(2) }} 课时({{
              gradeDetail.cocah_lesson_hour
            }}小时)
          </div>
        </div>
      </div>

      <TableTittle title="推荐参考书"></TableTittle>
      <div class="view">
        <div class="datum"
             v-for="(item, index) in subject_reference_list"
             :key="index">
          <div>专业课名称：{{ item.subject_name }}</div>
          <div v-for="(its, ins) in item.reference_list">
            参考书：{{ its.refence_name }}
          </div>
        </div>
        <div v-if="!subject_reference_list"
             class="datumNull">
          暂无推荐参考书
        </div>
      </div>

      <TableTittle title="教辅资料"></TableTittle>
      <div class="view">
        <div class="datum"
             v-for="(item, index) in subject_material_list"
             :key="index">
          <div>专业课名称：{{ item.subject_name }}</div>
          <div v-for="(its, ins) in item.material_list">
            教辅资料：{{ its.material_name }}
          </div>
        </div>
        <div v-if="!subject_material_list"
             class="datumNull">暂无教辅资料</div>
      </div>

      <TableTittle title="专业课考情分析"></TableTittle>
      <div class="view">
        <div class="datum">
          {{ exam_analyze }}
        </div>
        <div v-if="!exam_analyze"
             class="datumNull">暂无专业课考情分析</div>
      </div>

      <TableTittle title="课程具体安排"></TableTittle>
      <div class="view">
        <div class="datum">
          {{ course_arrange }}
        </div>
        <div v-if="!course_arrange"
             class="datumNull">暂无课程具体安排</div>
      </div>

      <TableTittle title="课程计划"></TableTittle>
      <div class="stage">
        <div class="stage-title">
          <div class="circle"></div>
          <div>基础阶段</div>
        </div>
        <div class="view"
             style="margin-top: 12px"
             v-for="(item, index) in basics"
             :key="index">
          <div class="view-index">计划{{ index + 1 }}</div>
          <div class="view-title">{{ item.course_theme }}</div>
          <div class="view-time">
            {{ $moment(item.plan_start_time).format("YYYY-MM-DD HH:mm") }} |
            {{ (item.plan_lesson_hour / rate).toFixed(2) }} 课时({{
              item.plan_lesson_hour
            }}小时)
          </div>
        </div>
        <div class="empty"
             v-if="basics.length <= 0">暂无此阶段计划</div>
      </div>
      <div class="stage">
        <div class="stage-title">
          <div class="circle"></div>
          <div>强化阶段</div>
        </div>
        <div class="view"
             style="margin-top: 12px"
             v-for="(item, index) in intensify"
             :key="index">
          <div class="view-index">计划{{ index + 1 }}</div>
          <div class="view-title">{{ item.course_theme }}</div>
          <div class="view-time">
            {{ $moment(item.plan_start_time).format("YYYY-MM-DD HH:mm") }} |
            {{ (item.plan_lesson_hour / rate).toFixed(2) }} 课时({{
              item.plan_lesson_hour
            }}小时)
          </div>
        </div>
        <div class="empty"
             v-if="intensify.length <= 0">暂无此阶段计划</div>
      </div>
      <div class="stage">
        <div class="stage-title">
          <div class="circle"></div>
          <div>冲刺阶段</div>
        </div>
        <div class="view"
             style="margin-top: 12px"
             v-for="(item, index) in sprint"
             :key="index">
          <div class="view-index">计划{{ index + 1 }}</div>
          <div class="view-title">{{ item.course_theme }}</div>
          <div class="view-time">
            {{ $moment(item.plan_start_time).format("YYYY-MM-DD HH:mm") }} |
            {{ (item.plan_lesson_hour / rate).toFixed(2) }} 课时({{
              item.plan_lesson_hour
            }}小时)
          </div>
        </div>
        <div class="empty"
             v-if="sprint.length <= 0">暂无此阶段计划</div>
      </div>

      <div class="footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button @click="dialogFormVisible = false"
                   plain>确定</el-button>
        <el-button type="primary"
                   @click="copy">复制图片</el-button>
        <div @click="copyWord"
             v-show="false"
             :data-clipboard-text="clipboardText"
             class="copy"
             ref="copys"></div>
      </div>

      <el-dialog title="提示"
                 :append-to-body="true"
                 :visible.sync="posterShow"
                 width="420px">
        <div class="imgView"
             ref="postHtml">
          <div class="header"
               v-cloak>
            <img src="https://upload.kaoyanbox.net/b79cd894ba09b48792cfb8ec531a4100.png"
                 style="width: 100%" />
          </div>
          <div class="module-view"
               style="margin-top: -280px">
            <div class="name">
              {{ gradeDetail.name }}
            </div>
            <img src="https://upload.kaoyanbox.net/4c64fdf4482f22043ea23316c5abe434.png"
                 class="class-img" />
            <div class="main"
                 style="
                padding-left: 25px;
                padding-top: 12px;
                border-radius: 0 20px 20px 20px;
              ">
              <div class="class-line">
                <img src="https://upload.kaoyanbox.net/d82e06b39294c4087d57345fcb540b01.png"
                     class="triangle" />
                <span class="line-label">班级类型</span>
                <span class="line-value">{{
                  gradeDetail.type == 0
                    ? "一对一"
                    : gradeDetail.type == 1
                    ? "小班课"
                    : gradeDetail.type == 2
                    ? "大班课"
                    : "试听课"
                }}</span>
              </div>
              <div class="class-line">
                <img src="https://upload.kaoyanbox.net/d82e06b39294c4087d57345fcb540b01.png"
                     class="triangle" />
                <span class="line-label">辅导院校</span>
                <span class="line-value">{{
                  gradeDetail.apply_college_name
                }}</span>
              </div>
              <div class="class-line">
                <img src="https://upload.kaoyanbox.net/d82e06b39294c4087d57345fcb540b01.png"
                     class="triangle" />
                <span class="line-label">辅导专业</span>
                <span class="line-value">{{
                  gradeDetail.apply_major_name
                }}</span>
              </div>
              <div class="class-line">
                <img src="https://upload.kaoyanbox.net/d82e06b39294c4087d57345fcb540b01.png"
                     class="triangle" />
                <span class="line-label">辅导学院</span>
                <span class="line-value">{{
                  gradeDetail.apply_academy_name
                }}</span>
              </div>
              <div class="class-line">
                <img src="https://upload.kaoyanbox.net/d82e06b39294c4087d57345fcb540b01.png"
                     class="triangle" />
                <span class="line-label">辅导科目</span>
                <span class="line-value">{{
                  getSubject(gradeDetail.apply_subjects)
                }}</span>
              </div>
              <div class="class-line">
                <img src="https://upload.kaoyanbox.net/d82e06b39294c4087d57345fcb540b01.png"
                     class="triangle" />
                <span class="line-label">辅导课时</span>
                <span class="line-value">{{
                    (Number(gradeDetail.cocah_lesson_hour) / rate).toFixed(2)
                  }}课时（{{ gradeDetail.cocah_lesson_hour }}小时）</span>
              </div>
            </div>
          </div>
          <div class="module-view">
            <img src="https://upload.kaoyanbox.net/d10f0f73eeb5c999515500aedb9b2c8b.png"
                 style="width: 100%" />
            <div class="main"
                 style="padding-left: 25px; padding-bottom: 12px">
              <div v-for="(item, index) in subject_reference_list"
                   :key="index"
                   style="margin-bottom: 12px">
                <div class="title">
                  {{ item.subject_name }}
                  <div class="circle"></div>
                </div>
                <div v-for="(its, ins) in item.reference_list">
                  {{ its.refence_name }}
                </div>
              </div>
              <div class="empty"
                   v-if="
                  !subject_reference_list || subject_reference_list.length <= 0
                ">
                <img src="https://upload.kaoyanbox.net/2bb7c77b9cfa4f5bcae25ab74f61a690.png" />
                <div>暂无推荐参考书</div>
              </div>
            </div>
          </div>
          <div class="module-view">
            <img src="https://upload.kaoyanbox.net/b9c36e1976520e1b8177c318077c4013.png"
                 style="width: 100%" />
            <div class="main"
                 style="padding-left: 25px; padding-bottom: 12px">
              <div v-for="(item, index) in subject_material_list"
                   :key="index"
                   style="margin-bottom: 12px">
                <div class="title">
                  {{ item.subject_name }}
                  <div class="circle"></div>
                </div>
                <div v-for="(its, ins) in item.material_list">
                  {{ its.material_name }}
                </div>
              </div>
              <div class="empty"
                   v-if="
                  !subject_material_list || subject_material_list.length <= 0
                ">
                <img src="https://upload.kaoyanbox.net/2bb7c77b9cfa4f5bcae25ab74f61a690.png" />
                <div>暂无教辅资料</div>
              </div>
            </div>
          </div>
          <div class="module-view">
            <img src="https://upload.kaoyanbox.net/db754a421396d7bb6adcb0c9ba66e312.png"
                 style="width: 100%" />
            <div class="main">
              {{ exam_analyze }}
              <div class="empty"
                   v-if="!exam_analyze">
                <img src="https://upload.kaoyanbox.net/2bb7c77b9cfa4f5bcae25ab74f61a690.png" />
                <div>暂无考情分析</div>
              </div>
            </div>
          </div>
          <div class="module-view">
            <img src="https://upload.kaoyanbox.net/568734009f39b5b4d196e5dfd10582ae.png"
                 style="width: 100%" />
            <div class="main">
              {{ course_arrange }}
              <div class="empty"
                   v-if="!course_arrange">
                <img src="https://upload.kaoyanbox.net/2bb7c77b9cfa4f5bcae25ab74f61a690.png" />
                <div>暂无具体安排</div>
              </div>
            </div>
          </div>
          <div class="module-view"
               style="background: wheat; border-radius: 20px; background: #f8faff">
            <img src="https://upload.kaoyanbox.net/2f864d71bf3da9f80c83b38e1bab8954.png"
                 style="width: 100%" />
            <div class="planHour">
              已计划<span>{{
                (Number(plan_exist_lesson_hour) / rate).toFixed(2)
              }}</span>课时/总<span>{{
                (Number(total_lesson_hour) / rate).toFixed(2)
              }}</span>课时
            </div>
            <div class="main">
              <div class="plan">
                <div class="plan-name">
                  <div class="name-tag"></div>
                  <div class="name-title">基础阶段</div>
                </div>
                <div v-for="(item, index) in basics"
                     :key="index">
                  <div class="plan-main">
                    <div class="main-index">
                      <div>计划{{ index + 1 }}</div>
                    </div>
                    <div class="main-theme">
                      {{ item.course_theme }}
                    </div>
                    <div class="mian-time">
                      <span>
                        {{
                          $moment(item.plan_start_time).format(
                            "YYYY-MM-DD HH:mm"
                          )
                        }}</span>
                      |
                      {{
                        (
                          Number(item.plan_lesson_hour) / rate.toFixed(2)
                        ).toFixed(2)
                      }}课时 ({{ item.plan_lesson_hour }}小时)
                    </div>
                  </div>
                </div>
                <div class="empty"
                     v-if="basics.length <= 0"
                     style="margin-top: 53px; margin-bottom: 80px">
                  <img src="https://upload.kaoyanbox.net/2bb7c77b9cfa4f5bcae25ab74f61a690.png" />
                  <div>暂无该阶段计划</div>
                </div>
              </div>

              <div class="plan">
                <div class="plan-name">
                  <div class="name-tag"></div>
                  <div class="name-title">强化阶段</div>
                </div>
                <div v-for="(item, index) in intensify"
                     :key="index">
                  <div class="plan-main">
                    <div class="main-index">
                      <div>计划{{ index + 1 }}</div>
                    </div>
                    <div class="main-theme">
                      {{ item.course_theme }}
                    </div>
                    <div class="mian-time">
                      <span>{{
                        $moment(item.plan_start_time).format("YYYY-MM-DD HH:mm")
                      }}</span>
                      |
                      {{
                        (
                          Number(item.plan_lesson_hour) / rate.toFixed(2)
                        ).toFixed(2)
                      }}课时 ({{ item.plan_lesson_hour }}小时)
                    </div>
                  </div>
                </div>
                <div class="empty"
                     v-if="intensify.length <= 0"
                     style="margin-top: 53px; margin-bottom: 80px">
                  <img src="https://upload.kaoyanbox.net/2bb7c77b9cfa4f5bcae25ab74f61a690.png" />
                  <div>暂无该阶段计划</div>
                </div>
              </div>

              <div class="plan">
                <div class="plan-name">
                  <div class="name-tag"></div>
                  <div class="name-title">冲刺阶段</div>
                </div>
                <div v-for="(item, index) in sprint"
                     :key="index">
                  <div class="plan-main">
                    <div class="main-index">
                      <div>计划{{ index + 1 }}</div>
                    </div>
                    <div class="main-theme">
                      {{ item.course_theme }}
                    </div>
                    <div class="mian-time">
                      <span>{{
                        $moment(item.plan_start_time).format("YYYY-MM-DD HH:mm")
                      }}</span>
                      |
                      {{
                        (
                          Number(item.plan_lesson_hour) / rate.toFixed(2)
                        ).toFixed(2)
                      }}课时 ({{ item.plan_lesson_hour }}小时)
                    </div>
                  </div>
                </div>
                <div class="empty"
                     v-if="sprint.length <= 0"
                     style="margin-top: 53px; margin-bottom: 80px">
                  <img src="https://upload.kaoyanbox.net/2bb7c77b9cfa4f5bcae25ab74f61a690.png" />
                  <div>暂无该阶段计划</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'

import html2canvas from 'html2canvas'
import {
  gradeOne,
  coursePlanGroupDetail,
  coursePlanlist,
  companyOne,
  getTempUserToken,
} from '@/request/api'
import { getStorage, adminUrl } from '@/utils/helper'
import Clipboard from 'clipboard'
// import { im } from "mathjs";
// import { manageUrl } from "@/utils/helper.js";
export default {
  components: {
    TableTittle,
  },
  data() {
    return {
      clipboardText: '',
      dialogFormVisible: false,
      grade_id: '',
      teacher_id: '',
      gradeDetail: {},
      rate: 0.75,
      totalClassHours: null, //改班级剩余时长
      total_lesson_hour: null, //计划总时长
      plan_exist_lesson_hour: null, //已提交总时长,
      subject_reference_list: null, //推荐参考书
      subject_material_list: null, //教辅资料
      exam_analyze: null, //考情分析
      course_arrange: null, //课程安排
      basics: [],
      intensify: [],
      sprint: [],
      gradeDetail: {},
      posterShow: false,
    }
  },
  created() {},
  methods: {
    getCompanyOne(id) {
      companyOne({ id: id }).then((response) => {
        let res = response.data
        if (res.code == 0) {
          this.rate = res.data.lesson_hour_hour_rate || 0.75
          //   console.log("op",res)
        }
      })
    },

    async copy() {
      this.posterShow = true
      setTimeout(() => {
        this.createPoster()
      }, 1000)
      return
      let str = adminUrl()
      let data = await this.$axios({
        url: `${str}/api/v1/back/member/getTempUserToken`,
        method: 'post', //可以省略 不写默认是get
        data: {},
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem('admin_token'),
        },
      })
      // console.log("token",data.data.data)
      // window.btoa('sdjajdakj1')
      if (location) {
        if (location.host.includes('manage.yanshizi.com')) {
          this.clipboardText = `https://lesson.kaoyanbox.com/#/pagesA/planPreview/index?grade_id=${
            this.grade_id
          }&teacher_id=${this.teacher_id}&token=${window.btoa(data.data.data)}`
        } else {
          this.clipboardText = `http://localhost:8083/#/pagesA/planPreview/index?grade_id=${
            this.grade_id
          }&teacher_id=${this.teacher_id}&token=${window.btoa(data.data.data)}`
          // this.clipboardText = `http://test-senior.kaoyanbox.net/#/pagesA/planPreview/index?grade_id=${this.grade_id}&teacher_id=${this.teacher_id}&token=${window.btoa(data.data.data)}`
        }
      }
      this.$nextTick(() => {
        // console.log
        this.$refs.copys.click()
      })
    },
    copyWord() {
      // console.log("111")
      var clipboard = new Clipboard('.copy')
      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功！',
          type: 'success',
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持自动复制',
          type: 'error',
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    open(data) {
      this.grade_id = data.id
      this.teacher_id = data.teacher_id
      this.getGradeOne()
      this.getCoursePlanGroupDetail()
      this.getCoursePlanlist()

      this.dialogFormVisible = true
    },

    getCoursePlanGroupDetail() {
      coursePlanGroupDetail({
        grade_id: this.grade_id,
        teacher_id: this.teacher_id,
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.subject_reference_list) {
            this.subject_reference_list = JSON.parse(
              res.data.data.subject_reference_list
            )
          } else {
            this.subject_reference_list = null
          }
          if (res.data.data.subject_material_list) {
            this.subject_material_list = JSON.parse(
              res.data.data.subject_material_list
            )
          } else {
            this.subject_material_list = null
          }
          this.exam_analyze = res.data.data.exam_analyze
          this.course_arrange = res.data.data.course_arrange
          // console.log("cc", this.subject_reference_list, this.subject_material_list)
        }
        // console.log("计划组详情", res)
      })
    },

    getGradeOne() {
      gradeOne({
        id: this.grade_id,
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.students) {
            this.getCompanyOne(res.data.data.students[0].cid)
          }
          this.gradeDetail = res.data.data
          this.totalClassHours =
            res.data.data.grade_plan_data.plan_last_lesson_hour
          this.total_lesson_hour =
            res.data.data.grade_plan_data.plan_total_lesson_hour
          this.plan_exist_lesson_hour =
            res.data.data.grade_plan_data.plan_exist_lesson_hour
        }
        // console.log("详情", res)
      })
    },
    // 辅导科目
    getSubject(e) {
      let subjectName = ''
      if (e && e.length > 0) {
        e.forEach((item) => {
          subjectName = subjectName + item.subject_name + ' '
        })
        return subjectName
      }
      return ''
    },

    // 获取计划
    getCoursePlanlist() {
      this.basics = []
      this.intensify = []
      this.sprint = []
      coursePlanlist({
        teacher_id: this.teacher_id,
        is_all: 1,
        plan_statuses: '20,21,22,40,41,42,43,45,46,0',
        isPaging: 0,
        // is_log_ref: 0,
        grade_id: this.grade_id, //班级id
      }).then((res) => {
        if (res.data.code == 0) {
          console.log('res', res.data)
          // this.planList = res.data.data.list
          // this.pageTotal = res.data.data.count
          res.data.data.list.forEach((item) => {
            if (item.course_stage == 0) {
              this.basics.push(item)
              this.basics = this.basics.sort(this.compare('plan_start_time'))
            } else if (item.course_stage == 1) {
              this.intensify.push(item)
              this.intensify = this.intensify.sort(
                this.compare('plan_start_time')
              )
            } else {
              this.sprint.push(item)
              this.sprint = this.sprint.sort(this.compare('plan_start_time'))
            }
          })

          // this.planList = [...this.basics, ...this.intensify, ...this.sprint]
        }
        // console.log(this.planList, 'res11111')
      })
    },
    // 封装数组对象时间从小到大进行排序
    compare(prop) {
      return function (a, b) {
        return a[prop] - b[prop] //升序
      }
    },
    createPoster() {
      const name = `计划预览`
      const posterHtml = this.$refs.postHtml

      let w = posterHtml.offsetWidth
      let h = posterHtml.offsetHeight
      // console.log("h", h);
      // 生成海报
      // console.log("生成海报", posterHtml, name);
      const domObj = posterHtml
      // console.log(domObj);
      html2canvas(domObj, {
        allowTaint: true,
        useCORS: true,
        width: w,
        height: h,
        // scrollY: -18,
        // scrollX: -0,
        onclone(doc) {},
      }).then((canvas) => {
        // document.body.appendChild(canvas)
        // console.log("结果", domObj, canvas);
        canvas.toBlob((blob) => {
          // 将blob对象放入剪切板item中
          // eslint-disable-next-line no-undef
          const data = [new ClipboardItem({ [blob.type]: blob })]
          // 写入剪切板,成功会执行resolve,失败则走reject
          navigator.clipboard.write(data).then(
            () => {
              this.$message({
                message: '已保存到粘贴板',
                type: 'success',
                duration: 2000,
              })
            },
            () => {
              this.$message({
                message: '保存截图失败',
                type: 'warning',
                duration: 2000,
              })
            }
          )
        }, 'image/png')

        this.downloadQRImg(canvas, name)
      })
    },
    /** 根据URL下载图片 */
    downloadQRImg(canvas, name) {
      /** 新Image对象，可以理解为DOM */
      let img = new Image()
      /** 解决跨域 Canvas 污染问题 */
      img.setAttribute('crossOrigin', 'anonymous')
      // img.onload = function() {
      /** canvas.toDataURL 返回的是一串Base64编码的URL,指定格式 PNG */
      let imgUrl = canvas.toDataURL('img/png')
      // console.log(imgUrl);
      img.src = imgUrl
      /** 生成一个a元素,并创建一个单击事件 */
      let a = document.createElement('a')
      a.download = name || 'photo' // 设置图片名称
      a.href = imgUrl // 将生成的URL设置为a.href属性
      a.setAttribute('id', 'myLink')
      document.body.appendChild(a)
      // console.log("链接", a);
      this.exportCodeConfirm()
      // }
    },
    /** 导出/下载 二维码 */
    exportCodeConfirm() {
      setTimeout(() => {
        let event = new MouseEvent('click')
        /** 触发a的单击事件 */
        document.getElementById('myLink').dispatchEvent(event)
      }, 0)
    },
  },
}
</script>

<style lang="less" scoped>
[v-cloak] {
  display: none;
}
.view {
  width: 100%;
  border-radius: 4px 4px 4px 4px;
  padding: 17px 16px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  margin-bottom: 24px;

  .line {
    display: flex;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    margin-top: 8px;

    .line-lef {
      color: #606266;
    }

    .line-rig {
      color: #303133;
      margin-left: 33px;
    }
  }

  .line:first-child {
    margin-top: 0px;
  }

  .datumNull,
  .datum {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
    margin-bottom: 10px;
    line-height: 20px;
  }

  .datumNull {
    color: #999999;
  }

  .view-index {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
  }

  .view-title {
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303133;
    margin: 10px 0;
  }

  .view-time {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
  }
}

.stage {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #303133;

  .stage-title {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .circle {
      width: 6px;
      height: 6px;
      background: #303133;
      border-radius: 50%;
      margin-right: 6px;
    }
  }

  .stage-title:first-child {
    margin-top: 0;
  }
}

.empty {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  margin: 8px 0;
}

.imgView {
  width: 375px;
  background: #ecf2fe;
  padding-bottom: 100px;
  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: middle !important;
  }

  .header {
    width: 100%;
    // position: relative;
  }

  .module-view {
    margin: 0 16px;
    margin-top: 24px;
    position: relative;
    .planHour {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7787a2;
      line-height: 20px;
      position: absolute;
      top: 11px;
      right: 15px;
      & > span:nth-of-type(1) {
        color: #1f86fe;
      }
      & > span:nth-of-type(2) {
        color: #3c485c;
      }
    }
    .name {
      width: 180px;
      font-size: 18px;
      font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
      font-weight: bold;
      color: #ffffff;
      line-height: 22px;
      position: absolute;
      top: 25px;
      left: 17px;
      z-index: 2;
      white-space: nowrap; //不换行
      overflow: hidden; //超出部分隐藏
      text-overflow: ellipsis; //文本溢出显示省略号
    }

    .class-line {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 12px;
      position: relative;

      .triangle {
        width: 6px;
        height: 7px;
        position: absolute;
        top: 50%;
        left: -13px;
        transform: translateY(-50%);
      }

      .line-label {
        color: rgba(0, 0, 0, 0.6);
        margin-right: 12px;
      }

      .line-value {
        color: rgba(0, 0, 0, 0.9);
      }
    }
    .class-line:first-child {
      margin-top: 0;
    }
    .class-img {
      width: 246px;
      height: 86px;
      margin-left: -17px;
      margin-bottom: -21px;
    }

    .main {
      // height: 100px;
      padding: 0 16px 24px 12px;
      background: #f8faff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2c3645;
      line-height: 20px;
      border-radius: 0 0 20px 20px;
      // border-radius:40px;
      // box-shadow: 0px 5px 5px -3px rgba(105, 158, 244, 0.15),
      //   0px 8px 10px 1px rgba(105, 158, 245, 0.06);
      border: 2px solid #ffffff;
      border-top: none;

      .title {
        margin-bottom: 4px;
        position: relative;
        .circle {
          width: 6px;
          height: 6px;
          background: #ff6400;
          position: absolute;
          border-radius: 50%;
          top: 50%;
          left: -13px;
          transform: translateY(-50%);
        }
      }
    }

    .plan {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      line-height: 20px;
      margin-bottom: 24px;

      .plan-name {
        display: flex;
        align-items: center;

        .name-tag {
          width: 3px;
          height: 12px;
          background: #ff6400;
          border-radius: 2px;
          margin-right: 4px;
        }

        .name-title {
        }
      }

      .plan-main {
        margin-top: 12px;
        padding: 16px;
        background: #ecf2fe;
        border: 2px solid #ffffff;
        border-radius: 8px;

        .main-index {
          font-size: 14px;
          font-weight: 400;
          color: #2c3645;
          line-height: 20px;
          display: flex;
          justify-content: space-between;
        }

        .main-theme {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 500;
          color: #2c3645;
          line-height: 20px;
          margin: 8px 0;

          img {
            width: 16px;
          }
        }

        .mian-time {
          font-size: 14px;
          font-weight: 400;
          color: #7787a2;
          line-height: 20px;
        }
      }
    }
  }

  .flex {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 32px);
  }

  .empty {
    img {
      width: 149px;
      height: 97px;
      margin-bottom: 6px;
    }
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bcc4d0;
    line-height: 17px;
    margin: 0 !important;
  }
}
</style>