import Vue from 'vue'
import Vuex from 'vuex'
import {
  dictionaryroot,
  companylist,
  crmrolelist,
  crmrulelist,
  userrulelist,
  crmdeptlist,
  userdeptlist,
  userrolelist,
  collegeList,
  expresslist,
  userlist,
  companyuserlist,
  grouplist,

} from "../request/api";
import { Message } from "element-ui";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 账单类型
    BillsTypeOptions: [],
    // 机构列表
    CompanyList: [],
    MechanismRoleList: [],
    crmRuleList: [],
    UserRuleList: [],
    crmdeptList: [],
    userdeptList: [],
    userroleList: [],
    ExpressOptions: [],
    collegeList: [],
    staffOptions: [],
    pipeiOptions:[],
    companyUserList: [],
    groupList: [],
    uncheckCount :0
  },
  mutations: {
    setUncheckCount( state ,uncheck){
      state.uncheckCount = uncheck
    },
    downLoad({ state }, fileUrl) {
      if (fileUrl) {
        window.open(fileUrl)
      } else {
        Message({
          message: '空文件，请上传文件',
          type: 'error',
          duration: 2000
        })
      }
    }
  },
  actions: {
    getAllColleges({ state }) {
      //获取院校列表
      let query = {
        isPaging: 0,
      };
      collegeList(query).then((res) => {
        state.collegeList = res.data.data.list || [];
      });
    },
    // 账单类型
    getBillsType({ state }) {
      let query = {
        code: 'BillsType',
      };
      dictionaryroot(query).then((res) => {
        state.BillsTypeOptions = res.data.data.values || [];
      });
    },
    // 机构列表
    getCompanyList({ state },data) {
      let query = {
        isPaging: 0,
      };
      if(data){
        query.data_range_tagses = data.data_range_tags
      }
      companylist(query).then((res) => {
        state.CompanyList = res.data.data.list || [];
      });
    },
    // 机构角色列表
    getMechanismList({ state }) {
      let query = {
        isPaging: 0,
      };
      crmrolelist(query).then((res) => {
        state.MechanismRoleList = res.data.data.list || [];
      });
    },
    // CRM权限列表
    getCRMRuleList({ state }) {
      let query = {
        isPaging: 0,
      };
      crmrulelist(query).then((res) => {
        state.crmRuleList = res.data.data.list || [];
      });
    },
    // 用户权限列表
    getUserRuleList({ state }) {
      let query = {
        isPaging: 0,
      };
      userrulelist(query).then((res) => {
        state.UserRuleList = res.data.data.list || [];
      });
    },
    // 机构部门列表
    getCrmDeptList({ state }) {
      let query = {
        isPaging: 0,
      };
      crmdeptlist(query).then((res) => {
        state.crmdeptList = res.data.data.list || [];
      });
    },
    // 用户部门列表
    getUserDeptList({ state }) {
      let query = {
        isPaging: 0,
      };
      userdeptlist(query).then((res) => {
        state.userdeptList = res.data.data.list || [];
      });
    },
    // 用户角色列表
    getUserRoleList({ state }) {
      let query = {
        isPaging: 0,
      };
      userrolelist(query).then((res) => {
        state.userroleList = res.data.data.list || [];
      });
    },
    // 快递列表
    getExpressOptions({ state }) {
      let query = {
        pageIndex: 1,
        pageSize: 10
      }
      expresslist(query).then((res) => {
        state.ExpressOptions = res.data.data.list || [];
      });
    },
    // 总端员工列表
    getStaffOptions({ state }) {
      let query = {
        isPaging: 0,

      };
      userlist(query).then((res) => {
      	
        state.staffOptions = res.data.data.list || [];
      });
    },
     // 总端匹配员工列表
    getpipeiOptions({ state }) {
      let query = {
        isPaging: 0,
        
        
      };
      userlist(query).then((res) => {
      	
        state.pipeiOptions = res.data.data.list || [];
      });
    },
    // 机构员工列表（账号管理里的不分页列表数据）
    getCompanyUser({ state }) {
      let query = {
        isPaging: 0,
      };
      companyuserlist(query).then((res) => {
        state.companyUserList = res.data.data.list || [];
      });
    },
    // 机构组管理
    getCompanyGroup({ state }) {
      let query = {
        isPaging: 0,
      };
      grouplist(query).then((res) => {
        state.groupList = res.data.data.list || [];
      });
    },
  },
  modules: {
  }
})
