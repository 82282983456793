<template>
  <div class="dialog-wrap">
    <el-dialog append-to-body
               top="5vh"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               @close="handleClose"
               width="1280px"
               :show-close="false">
      <div class="header">
        <div class="title">学员列表</div>
        <div class="dialog-footer">
          <el-button type="primary"
                     @click="handleToChoose"
                     size="small">新 增</el-button>
          <el-button @click="handleToSubmit"
                     size="small">关 闭</el-button>
        </div>
      </div>
      <el-table class="result"
                ref="multipleTable"
                :data="results"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column prop="apply_year"
                         label="考研年份"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            {{scope.row.apply_year?`${scope.row.apply_year}年`:""}}
          </template>
        </el-table-column>
        <el-table-column label="招生机构"
                         align="center"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.company?`${scope.row.company.company_name}/${scope.row.company.contact_name}`:""}}
          </template>
        </el-table-column>
        <el-table-column prop="realname"
                         label="学员姓名"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="学习状态"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            <el-tag size="mini"
                    v-show="scope.row.student_grade_status == 1">正常</el-tag>
            <el-tag size="mini"
                    type="info"
                    v-show="scope.row.student_grade_status == 0">终止</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="120"
                         show-overflow-tooltip
                         align="center">
          <template slot="header">
            <el-popover ref="popover">
              <span class="el-dropdown-link"
                    slot="reference">
                辅导时长<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <div class="popover-item"
                   @click="handleCommand(1)">小时</div>
              <div class="popover-item"
                   @click="handleCommand(2)">课时</div>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <div v-if="unitConversion==1">{{scope.row.service_hours}}小时</div>
            <div v-if="unitConversion==2">{{(scope.row.service_hours/scope.row.company.lesson_hour_hour_rate).toFixed(2)}}课时</div>
          </template>
        </el-table-column>
        <el-table-column width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot="header">
            <el-popover ref="popover">
              <span class="el-dropdown-link"
                    slot="reference">
                已安排课时<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <div class="popover-item"
                   @click="handleCommandOther(1)">小时</div>
              <div class="popover-item"
                   @click="handleCommandOther(2)">课时</div>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <div v-if="unitConversion2==1">{{scope.row.sum_student_lesson_hour_arranged||0}}小时</div>
            <div v-if="unitConversion2==2">{{Number((scope.row.sum_student_lesson_hour_arranged/scope.row.company.lesson_hour_hour_rate).toFixed(2))}}课时</div>
          </template>
        </el-table-column>
        <el-table-column label="课程进度"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-progress :text-inside="true"
                         :stroke-width="20"
                         :percentage="scope.row.course_progress?scope.row.course_progress:0"></el-progress>
          </template>
        </el-table-column>
        <el-table-column label="课程状态"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            <el-tag size="mini"
                    v-if="scope.row.student_sync_status == 1"
                    type="warning">待对接</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.student_sync_status == 2"
                    type="warning">待分配</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.student_sync_status == 3">匹配中</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.student_sync_status == 4">上课中</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.student_sync_status == 5"
                    type="info">已终止</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.student_sync_status == 6"
                    type="danger">已结束</el-tag>
            <!--  <el-tag
              size="small"
              v-show="scope.row.course_status == 1"
              type="warning"
            >待上课</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.course_status == 2"
            >上课中</el-tag>
            <el-tag
              size="small"
              type="info"
              v-show="scope.row.course_status == 3"
            >上课结束</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column label="报考信息"
                         width="500"
                         align="left"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.apply_college_name}} | ({{scope.row.apply_major_code}}){{scope.row.apply_major_name}} | {{scope.row.apply_academy_name}}
            |
            <span v-for="(item,index) in scope.row.subjects||[]"
                  :key="index
            ">
              ({{item.subject_code}}){{item.subject_name}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remark"
                         label="课程备注"
                         align="center"
                         width="300"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="学管师"
                         align="center"
                         width="130">
          <template slot-scope="scope">
            {{scope.row.learn_manager?scope.row.learn_manager.nickname:""}}
          </template>
        </el-table-column>
        <el-table-column label="对接人"
                         align="center"
                         width="130"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ formateManage_Watcher(scope.row.manage_watcher)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="报名日期"
                         width="150"
                         align="center">
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column label="修改日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         align="center"
                         width="200">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="
              false"
                     v-if="scope.row.before_log_student_addconfirm_list"
                     @click="handleConfirm(scope.row)">待补充确认课时</el-link>
            <el-link type="primary"
                     :underline="
              false"
                     @click="handleDetail(scope.row)">查看详情</el-link>
            <el-link type="danger"
                     :underline="false"
                     @click="handleToChangeStatus(scope.row,'确定要将此学员移除班级吗',0)"
                     v-if="scope.row.student_grade_status==1">移除班级</el-link>
            <el-link type="primary"
                     :underline="
              false"
                     @click="handleToChangeStatus(scope.row,'确定要将此学员重新加入班级吗',1)"
                     v-if="scope.row.student_grade_status==0">加入班级</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination background
                       small
                       layout="total, prev, pager, next,sizes, jumper"
                       :current-page="Number(query.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(query.pageSize)"
                       :total="Number(pageTotal)"
                       @size-change="handleSizeChange"
                       @current-change="handlePageChange"></el-pagination>
      </div>

    </el-dialog>
    <ChooseStudent :id="ChooseStudentId"
                   :isVisible="chooseDialog"
                   @close="chooseDialog=false"
                   @submit="fetchList(true)" />

    <StudentDetail :isVisible="StudentDetailDialog"
                   @close="StudentDetailDialog=false"
                   :id="studentId"></StudentDetail>
    <Confirm :isVisible="confirmDialog"
             :confrimList="confrimList"
             @close="close"
             @submit="fetchList(true)"></Confirm>
  </div>
</template>

<script>
import { studentlist, gradeEdit, commonHandleLogCreate } from '@/request/api'
import StudentDetail from '@/components/DialogComponents/TeacherResources/StudentDetail.vue'
import ChooseStudent from '@/components/DialogComponents/ClassService/ChooseStudent.vue'
import Confirm from '@/components/DialogComponents/ClassService/replenishConfirm.vue'

export default {
  components: {
    ChooseStudent,
    StudentDetail,
    Confirm,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      confirmDialog: false,
      studentId: null,
      StudentDetailDialog: false,
      dialogVisible: this.isVisible,
      query: {
        keyword: '',
        college: '',
        type: '',
        status: '',
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,
      results: [],
      confrimList: [],
      ChooseStudentId: null,
      chooseDialog: false,
      unitConversion: 1,
      unitConversion2: 1,
    }
  },

  watch: {
    isVisible(val) {
      this.dialogVisible = val
      if (val) {
        this.query = {
          pageIndex: 1,
          pageSize: 10,
          isPaging: 1,
        }
        this.fetchList()
      }
    },
  },
  methods: {
    handleConfirm(row) {
      this.confrimList = row.before_log_student_addconfirm_list
      console.log('dsd', this.confrimList)
      this.dialogVisible = false
      this.confirmDialog = true
    },
    close() {
      this.confirmDialog = false
    },
    handleDetail(row) {
      this.studentId = row.id
      this.StudentDetailDialog = true
    },
    emitClose() {
      this.$emit('close')
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      let params = { ...this.query, grade_id: this.id }
      studentlist(params).then((response) => {
        let res = response.data
        this.results = res.data.list || []
        this.pageTotal = res.data.count
      })
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields()
      this.fetchList()
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, 'pageIndex', val)
      this.fetchList()
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val
      this.diffSelection()
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: '',
        type: '',
        status: '',
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      }
      this.$emit('close')
    },

    collegeFormate(row) {
      if (
        row.apply_college_name &&
        row.apply_major_code &&
        row.apply_major_name
      ) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`
      } else {
        return '暂无定校'
      }
    },
    formateManage_Watcher(val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.nickname + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
    handleToChoose() {
      this.ChooseStudentId = this.id

      this.chooseDialog = true
    },
    handleToSubmit() {
      this.$emit('submit')
      this.handleClose()
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
    handleCommandOther(e) {
      this.unitConversion2 = e
      this.$refs['popover'].doClose()
    },
    handleToChangeStatus(row, text, status) {
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        row.student_grade_status = status
        let query = {
          id: this.id,
          arr_student: this.results.map((v) => {
            return {
              student_id: v.id,
              student_grade_status: v.student_grade_status,
            }
          }),
        }
        gradeEdit(query).then((res) => {
          if (res.data.code == 0) {
            this.createLog(row)
          }
        })
      })
    },
    createLog(row) {
      let query = {
        ref_id: this.id,
        handle_type: 0,
        handle_child_type: 3,
        handle_content: `将${row.realname}学员移出了班级`,
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.fetchList(true)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
  padding-top: 0px;
}
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
  .now {
    margin-left: 16px;
    color: red;
  }
  .header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
    }
  }
}
</style>
